import { t } from "i18next";
import img1 from "../src/assets/Images/image1.png";
import img2 from "../src/assets/Images/image2.png";
import img3 from "../src/assets/Images/image3.png";
import furnitureImg from "../src/assets/Images/furnitureImg.png";
import homeRemImg from "../src/assets/Images/homeRemImg.png";
import b2bImg from "../src/assets/Images/b2bImg.png";
// import workImage1 from "../src/assets/Images/workImg1.png";
// import workImage2 from "../src/assets/Images/workImg2.png";
// import workImage3 from "../src/assets/Images/workImg3.png";
// import workImage1 from "../src/assets/Images/howWorksImg1.png";
import workImage1 from "../src/assets/Images/howsworksNew.png";
import workImage2 from "../src/assets/Images/howWorksImg2.png";
import workImage3 from "../src/assets/Images/howWorksImg1.png";
import calenderImg from "../src/assets/Images/calenderImg.webp";
import driverPicking from "../src/assets/Images/driverPicking.webp";
import driverDelivery from "../src/assets/Images/driverDelivery.webp";
import lutonVan from "../src/assets/Images/Luton_Van.jpg";
import extraLongWheel from "../src/assets/Images/Extra_Long_Wheel_Base.jpg";
import longWheel from "../src/assets/Images/Large_Long_Wheel_Base_Van.jpg";
import mediumVan from "../src/assets/Images/Medium_Removals_Van.jpg";
import smallVan from "../src/assets/Images/Small_Moving_Van.jpg";
import furniture from "../src/assets/Images/furniture.png";
import homeRemovals from "../src/assets/Images/homeRemovals.png";
import businessToBusiness from "../src/assets/Images/businessToBusiness.png";
import { Icon } from "@iconify/react";
import imgs1 from "../src/assets/DriverImages/Vector.png";
import imgs2 from "../src/assets/DriverImages/vector3.png";
import imgs3 from "../src/assets/DriverImages/vector2.png";

export const Categories = [
  {
    id: "1",
    text: `${t("FURNITURE_OTHER")} `,
    image: `${furnitureImg}`,
  },
  {
    id: "2",
    text: `${t("HOME_REMOVAL")} `,
    image: `${homeRemImg}`,
  },
  {
    id: "3",
    text: `${t("BTOB")} `,
    image: `${b2bImg}`,
  },
];

export const Works = [
  {
    id: "1",
    text: `${t("SCHEDULE_PICKUP")} `,
    para: "Lorem Ipsum is simply dummy text and Printing typesetting industry.  ",
    image: `${calenderImg}`,
  },
  {
    id: "2",
    text: `${t("ARRIVE")} `,
    para: "Lorem Ipsum is simply dummy text and Printing typesetting industry.  ",
    image: `${driverPicking}`,
  },
  {
    id: "3",
    text: `${t("DELIVER")} `,
    para: "Lorem Ipsum is simply dummy text and Printing typesetting industry.  ",
    image: `${driverDelivery}`,
  },
];

export const HeaderData = [
  {
    id: "1",
    text: `${t("HOME")} `,
    link: `/`,
  },
  {
    id: "5",
    text: `${t("BOOK_A_JOB")}`,
    link: `/create-job`,
  },
  {
    id: "4",
    text: `${t("VAN_GUIDE_TEXT")} `,
    link: `/van-guide`,
  },
  {
    id: "3",
    text: `${t("FAQ")} `,
    link: `/faq`,
  },
  // {
  //   id: "2",
  //   text: `${t("DRIVERS_LOGIN")} `,
  //   link: `/driver-login`,
  // },

  // {
  //   id: "4",
  //   text: `${t("SUPPORT")}`,
  //   link: `javascript:`,
  // },
];

export const HeaderData1 = [
  {
    id: "1",
    text: `${t("MY_BOOKINGS")}`,
    link: `/jobs`,
  },
  {
    id: "2",
    text: `${t("BOOK_A_JOB")}`,
    link: `/create-job`,
  },
  {
    id: "3",
    text: `${t("FAQ")} `,
    link: `/faq`,
  },
  {
    id: "4",
    text: `${t("VAN_GUIDE_TEXT")} `,
    link: `/van-guide`,
  },
];

export const FooterData = [
  {
    id: "1",
    text: `${t("PRIVACY")}`,
    link: `/privacy-and-policy`,
    type: "privacy",
  },
  {
    id: "2",
    text: `${t("TERMS")} `,
    link: `/terms-and-conditions`,
    type: "terms",
  },
];

export const furnitureItems = [
  {
    name: "Sofas",
    icon: <Icon icon="mdi:sofa-outline" color="grey" width="22" height="22" />,
  },
  {
    name: "Beds & Mattresses",
    icon: (
      <Icon
        icon="material-symbols-light:bed"
        color="grey"
        width="22"
        height="22"
      />
    ),
  },
  {
    name: "Boxes",
    icon: <Icon icon="f7:cube-box" color="grey" width="22" height="22" />,
  },
  {
    name: "Wardrobes",
    icon: (
      <Icon icon="mdi:wardrobe-outline" color="grey" width="22" height="22" />
    ),
  },
  {
    name: "Tables",
    icon: (
      <Icon
        icon="material-symbols:table-bar-outline"
        color="grey"
        width="22"
        height="22"
      />
    ),
  },
  {
    name: "Chairs",
    icon: (
      <Icon
        icon="game-icons:wooden-chair"
        color="grey"
        width="22"
        height="22"
      />
    ),
  },
];

export const sofasOptions = [
  {
    id: 1,
    name: "Two Seater Sofa",
    quantity: 1,
  },
  {
    id: 2,
    name: "Three Seater Sofa",
    quantity: 2,
  },
  {
    id: 3,
    name: "Four Seater Sofa",
    quantity: 2,
  },

  {
    id: 4,
    name: "Five Seater Sofa",
    quantity: 1,
  },
  {
    id: 5,
    name: "Armchair Chair",
    quantity: 1,
  },
  {
    id: 6,
    name: "Couch",
    quantity: 2,
  },
];

export const boxesOptions = [
  {
    id: 1,
    name: "Small",
    quantity: 1,
  },
  {
    id: 2,
    name: "Medium",
    quantity: 1,
  },
  {
    id: 3,
    name: "Large",
    quantity: 1,
  },
];

export const unitOptions = {
  length: [
    {
      id: 1,
      value: "CM",
    },
    {
      id: 2,
      value: "Inch",
    },
  ],
  weight: [
    {
      id: 1,
      value: "KG",
    },
    {
      id: 2,
      value: "Pound",
    },
  ],
};

export const vehicleOptions = [
  {
    id: 1,
    name: "LUTON VAN",
    title: "Lorem Ipsum is simply dummy text",
    image: lutonVan,
  },
  {
    id: 2,
    name: "LARGE VAN",
    title: "Lorem Ipsum is simply dummy text",
    image: lutonVan,
  },
  // {
  //   id: 3,
  //   name: "MEDIUM VAN",
  //   title: "Lorem Ipsum is simply dummy text",
  //   image: lutonVan,
  // },
  // {
  //   id: 4,
  //   name: "SMALL VAN",
  //   title: "Lorem Ipsum is simply dummy text",
  //   image: lutonVan,
  // },
];

export const DriverHeaderData = [
  {
    id: "1",
    text: `${t("HOME")} `,
    link: `/driver-home`,
  },
  {
    id: "2",
    text: `${t("ABOUT_US")}`,
    link: `/driver-about-us`,
  },
  {
    id: "3",
    text: `${t("FAQ")} `,
    link: `/faq-driver`,
  },
  {
    id: "4",
    text: `${t("VAN_GUIDE_TEXT")} `,
    link: `/driver-van-guide`,
  },
];

export const helperOptions = [
  {
    id: 1,
    val: "No Helper",
  },
  {
    id: 2,
    val: "Driver Helping",
  },
  {
    id: 3,
    val: "Driver + 1",
  },
  {
    id: 4,
    val: "Driver + 2",
  },
];

export const categoriesList = [
  { id: "lejRej", name: "FURNITURE_AND_OTHER", image: furniture },
  { id: "mbk5ez", name: "HOME_REMOVAL", image: homeRemovals },
  { id: "nel5aK", name: "BTOB", image: businessToBusiness },
];

export const DriverSettingData = [
  {
    id: "1",
    text: `${t("EDIT_PROFILE")} `,
    link: `/driver-profile`,
  },
  {
    id: "2",
    text: `${t("AVAILABILITY")}`,
    link: "/schedule",
  },
  {
    id: "3",
    text: `${t("JOB_HISTORY")}`,
    link: "/driver-job-history",
  },
  {
    id: "4",
    text: `${t("PRICE")}`,
    link: "/driver-price",
  },
  {
    id: "5",
    text: `${t("SERVICES")}`,
    link: "/service",
  },
  {
    id: "14",
    text: `${t("PACKING_HELPER_SETTINGS")}`,
    link: "/packing",
  },
  {
    id: "6",
    text: `${t("RADIUS")}`,
    link: "/driver-radius",
  },
  {
    id: "7",
    text: `${t("CHANGE_PASSWORD")}`,
    link: "/driver-change-password",
  },
  {
    id: "8",
    text: `${t("BANK_ACCOUNT")}`,
    link: "/driver-bank-account",
  },
  {
    id: "9",
    text: `${t("TUTORIAL")}`,
    link: `/driver-tutorial`,
  },
  {
    id: "10",
    text: `${t("SUPPORT")}`,
    link: `/driver-support`,
  },
  {
    id: "11",
    text: `${t("ABOUT_US")}`,
    link: `/driver-about-us`,
  },
  {
    id: "12",
    text: `${t("PRIVACY")}`,
    link: `/driver-privacy-policy`,
  },
  {
    id: "13",
    text: `${t("TERMS")}`,
    link: `/driver-terms-conditions`,
  },
];

export const UserSettingData = [
  {
    id: "1",
    text: `${t("EDIT_PROFILE")}`,
    link: `/profile`,
  },
  {
    id: "2",
    text: `${t("JOB_HISTORY")}`,
    link: `/job-history`,
  },
  {
    id: "3",
    text: `${t("CHANGE_PASSWORD")}`,
    link: `/change-password`,
  },
  {
    id: "4",
    text: `${t("SAVE_CARD")}`,
    link: `/save-cards`,
  },
  {
    id: "5",
    text: `${t("SUPPORT")}`,
    link: `/support`,
  },
  {
    id: "6",
    text: `${t("ABOUT_US")}`,
    link: `/about-us`,
  },
  {
    id: "7",
    text: `${t("PRIVACY")}`,
    link: `/privacy-policy`,
  },
  {
    id: "8",
    text: `${t("TERMS")}`,
    link: `/terms-conditions`,
  },
];

export const UserSettingAccordian = [
  {
    id: "1",
    text: `${t("EDIT_PROFILE")}`,
    link: `/profile`,
    type: false,
  },
  {
    id: "2",
    text: `${t("JOB_HISTORY")}`,
    link: `/job-history`,
    type: "anotherRoute",
  },
  {
    id: "3",
    text: `${t("CHANGE_PASSWORD")}`,
    link: `javascript:`,
    type: false,
  },
  {
    id: "4",
    text: `${t("SAVE_CARD")}`,
    link: `javascript:`,
    type: false,
  },
  {
    id: "5",
    text: `${t("SUPPORT")}`,
    link: `javascript:`,
    type: false,
  },
  {
    id: "6",
    text: `${t("ABOUT_US")}`,
    link: `javascript:`,
    type: false,
  },
  {
    id: "7",
    text: `${t("PRIVACY")}`,
    link: `javascript:`,
    type: false,
  },
  {
    id: "8",
    text: `${t("TERMS")}`,
    link: `javascript:`,
    type: false,
  },
  {
    id: "9",
    text: `${t("DELETE_ACCOUNT")}`,
    link: `delete-account`,
    type: "modal",
  },
  {
    id: "10",
    text: `${t("LOGOUT")}`,
    link: `logout`,
    type: "modal",
  },
];

export const vehicleDescription = [
  {
    id: 1,
    title: `${t("VAN_TITLE")}`,
    description: `${t("VAN_DESCRIPTION")}`,
    dimention: {
      length: `${t("INFO_LENGTH1")}`,
      width: `${t("INFO_LENGTH2")}`,
      height: `${t("INFO_LENGTH3")}`,
      payload: `${t("INFO_LENGTH4")}`,
      seats: `${t("INFO_LENGTH5")}`,
    },
    image: lutonVan,
  },
  {
    id: 5,
    title: `${t("XTRA_LARGE_VAN_TITLE")}`,
    description: `${t("XTRA_LARGE_VAN_DESCRIPTION")}`,
    dimention: {
      length: `${t("XTRA_LARGE_VAN_LENGTH1")}`,
      width: `${t("XTRA_LARGE_VAN_LENGTH2")}`,
      height: `${t("XTRA_LARGE_VAN_LENGTH3")}`,
      payload: `${t("XTRA_LARGE_VAN_LENGTH4")}`,
      seats: `${t("XTRA_LARGE_VAN_LENGTH5")}`,
    },
    image: extraLongWheel,
  },
  {
    id: 2,
    title: `${t("LARGE_VAN_TITLE")}`,
    description: `${t("LARGE_VAN_DESCRIPTION")}`,
    dimention: {
      length: `${t("LARGE_VAN_LENGTH1")}`,
      width: `${t("LARGE_VAN_LENGTH2")}`,
      height: `${t("LARGE_VAN_LENGTH3")}`,
      payload: `${t("LARGE_VAN_LENGTH4")}`,
      seats: `${t("LARGE_VAN_LENGTH5")}`,
    },
    image: longWheel,
  },
  {
    id: 3,
    title: `${t("MEDIUM_VAN_TITLE")}`,
    description: `${t("MEDIUM_VAN_DESCRIPTION")}`,
    dimention: {
      length: `${t("MEDIUM_LENGTH1")}`,
      width: `${t("MEDIUM_LENGTH2")}`,
      height: `${t("MEDIUM_LENGTH3")}`,
      payload: `${t("MEDIUM_LENGTH4")}`,
      seats: `${t("MEDIUM_LENGTH5")}`,
    },
    image: mediumVan,
  },
  {
    id: 4,
    title: `${t("SMALL_VAN_TITLE")}`,
    description: `${t("SMALL_VAN_DESCRIPTION")}`,
    dimention: {
      length: `${t("SMALL_LENGTH1")}`,
      width: `${t("SMALL_LENGTH2")}`,
      height: `${t("SMALL_LENGTH3")}`,
      payload: `${t("SMALL_LENGTH4")}`,
      seats: `${t("SMALL_LENGTH5")}`,
    },
    image: smallVan,
  },
];

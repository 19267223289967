import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import logo from "../../assets/Images/logo.png";
// import logo2 from "../../assets/Images/logo2.png";
import logoWhite from "../../assets/Images/logoWhite.png";
import logoWhiteSide from "../../assets/Images/logoWhiteSide.png";
// import logoSmall from "../../assets/Images/logoSmall.png";
// import logoSmall from "../../assets/Images/logo3.png";
// import finalLogo from "../../assets/Images/finalLogo.png";
import { NavLink, useNavigate } from "react-router-dom";
import "../../assets/Styles/Common.css";
import { HeaderData, HeaderData1 } from "../../mockData";
import styles from "../Header/Header.module.css";
// import { BiSolidBell } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import { FiGlobe } from "react-icons/fi";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "react-bootstrap";
import { setCurrentLang } from "../../store/slices/UserSlice";
import Footer from "../Footer/Footer";
import facebook from "../../assets/Images/faceBook.png";
import twitter from "../../assets/Images/twitter.png";
import pinrest from "../../assets/Images/pinrest.png";
import linkedin from "../../assets/Images/linkedin.png";
// import vanLoginImg from "../../assets/Images/vanLoginImg.png";
import vanLogin from "../../assets/Images/vanLogin.webp";
import NotificationPage from "../Notification/NotificationPage";

//---------function for header----------
function Header({ children }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, currentLang, profileImage } = useSelector(
    (state) => state.user
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLang);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    dispatch(setCurrentLang(language));
  };

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "210px";
    // document.body.style.overflow = "hidden";
    setIsSideBarOpen(true);
    if (isSideBarOpen) {
      closeNav();
    }
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    // document.body.style.overflow = "auto";
    setIsSideBarOpen(false);
  };

  return (
    <>
      <section>
        {/* navbar header */}
        <div className={`${styles.navbarshow} ${styles.headerSection}`}>
          <Navbar expand="lg">
            <Container>
              <Navbar.Brand>
                <NavLink to="/">
                  <img
                    src={logoWhite}
                    alt="logo"
                    className={styles.headerLogo}
                  />
                </NavLink>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                id="basic-navbar-nav"
                className="justify-content-end"
              >
                {Object.keys(currentUser).length == 0 ? (
                  <Nav className="activeShow justify-content-end">
                    {HeaderData.map((item, index) => (
                      <NavLink to={item.link} key={index}>
                        {item.text}
                      </NavLink>
                    ))}
                    <img
                      src={vanLogin}
                      onClick={() => navigate("/driver-login")}
                      className={styles.vanLoginImg}
                      alt="van-image"
                    ></img>
                    <button
                      type="button"
                      onClick={() => navigate("/login")}
                      className={styles.loginButton}
                    >
                      {t("MY_ACCOUNT")}
                    </button>
                  </Nav>
                ) : (
                  <Nav
                    className={`${styles.navbarAfterLogin} activeShow justify-content-end`}
                  >
                    {HeaderData1.map((item, index) => (
                      <NavLink to={item.link} key={index}>
                        {item.text}
                      </NavLink>
                    ))}
                    <NotificationPage />
                    <div className={styles.profileImg}>
                      <img
                        src={profileImage}
                        alt="profile-image"
                        onClick={() => navigate("/profile")}
                      />
                    </div>
                  </Nav>
                )}
                {/*
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle className={styles.toggleButton}>
                                        <FiGlobe /> {selectedLanguage.toUpperCase()}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => changeLanguage('en')}>
                                            <span role="img" aria-label="English">🇺🇸</span> {t("ENGLISH")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => changeLanguage('es')}>
                                            <span role="img" aria-label="Spanish">🇪🇸</span> {t("SPANISH")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => changeLanguage('fr')}>
                                            <span role="img" aria-label="French">🇫🇷</span> {t("FRENCH")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => changeLanguage('tr')}>
                                            <span role="img" aria-label="Turkish">🇹🇷</span> {t("TURKISH")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => changeLanguage('ar')}>
                                            <span role="img" aria-label="Arabic">🇸🇦</span>{t("CHINESE")}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => changeLanguage('zh')}>
                                            <span role="img" aria-label="Chinese">🇨🇳</span> {t("ARABIC")}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>*/}
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>

        {/* sidebar header */}
        <div className={styles.sidebarShow}>
          <div
            id="mySidenav"
            className={`${styles.sidenav} ${isSideBarOpen ? styles.open : ""}`}
          >
            <div>
              <NavLink to="/">
                <div className={styles.sideLogo}>
                  <img src={logoWhiteSide} alt="logo" />
                </div>
              </NavLink>
              <div
                // href="javascript:void(0)"
                className={styles.closebtn}
                onClick={closeNav}
              >
                &times;
              </div>
              {Object.keys(currentUser).length == 0 ? (
                <>
                  <div className={styles.sideWrapper}>
                    <div>
                      <div className="activeShow">
                        {HeaderData.map((item, index) => (
                          <div key={index}>
                            <NavLink
                              to={item.link}
                              key={index}
                              onClick={() => closeNav()}
                            >
                              {item.text}
                            </NavLink>
                            <hr className={styles.tabHrDivider} />
                          </div>
                        ))}
                      </div>
                      <img
                        src={vanLogin}
                        onClick={() => navigate("/driver-login")}
                        className={styles.vanLoginImgSidebar}
                        alt="van-img"
                      ></img>
                      <hr className={styles.tabHrDivider} />
                      <div className={styles.buttonCenter}>
                        <button
                          type="button"
                          onClick={() => navigate("/login")}
                          className={styles.loginButton}
                        >
                          {t("LOGIN")}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="activeShow">
                    {HeaderData1.map((item, index) => (
                      <>
                        <NavLink
                          to={item.link}
                          key={index}
                          onClick={() => closeNav()}
                        >
                          {item.text}
                        </NavLink>
                        <hr className={styles.tabHrDivider} />
                      </>
                    ))}
                  </div>
                </>
              )}

              <div className={styles.buttonCenter}>
                {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle className={styles.loginButton}>
                                    <FiGlobe /> {selectedLanguage.toUpperCase()}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => changeLanguage('en')}>
                                        <span role="img" aria-label="English">🇺🇸</span> {t("ENGLISH")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => changeLanguage('es')}>
                                        <span role="img" aria-label="Spanish">🇪🇸</span> {t("SPANISH")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => changeLanguage('fr')}>
                                        <span role="img" aria-label="French">🇫🇷</span> {t("FRENCH")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => changeLanguage('tr')}>
                                        <span role="img" aria-label="Turkish">🇹🇷</span> {t("TURKISH")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => changeLanguage('ar')}>
                                        <span role="img" aria-label="Arabic">🇸🇦</span>{t("CHINESE")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => changeLanguage('zh')}>
                                        <span role="img" aria-label="Chinese">🇨🇳</span> {t("ARABIC")}
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown> */}
              </div>
            </div>
            <div className={styles.socialIcons}>
              <div>
                <a
                  href={process.env.REACT_APP_FACEBOOK_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} alt="facebook" />
                </a>
              </div>
              <div>
                <a
                  href={process.env.REACT_APP_TWITTER_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="Twitter" />
                </a>
              </div>
              <div>
                <a
                  href={process.env.REACT_APP_PINTEREST_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={pinrest} alt="Pinterest" />
                </a>
              </div>
              <div>
                <a
                  href={process.env.REACT_APP_LINKEDIN_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="LinkedIn" />
                </a>
              </div>
            </div>
          </div>
          <div
            className={`${styles.sidebarNotification} ${
              isSideBarOpen ? "moveSideContent" : ""
            }`}
          >
            <div
              style={{
                fontSize: "30px",
                cursor: "pointer",
                marginLeft: "15px",
                color: "#FFFFFF",
              }}
              onClick={openNav}
            >
              &#9776;{" "}
            </div>
            {/* {!isSideBarOpen && ( */}
            <div onClick={() => navigate("/")}>
              <img src={logoWhite} className={styles.smallLogo} alt="logo" />
            </div>
            {/* )} */}
            <div style={{ marginRight: "18px" }}>
              {Object.keys(currentUser).length !== 0 && <NotificationPage />}
            </div>
          </div>
        </div>
      </section>
      <div className={isSideBarOpen ? "moveSideContent" : ""}>
        {children}
        <Footer />
      </div>
    </>
  );
}
export default Header;

import { useTranslation } from "react-i18next";
import homeBg from "../../assets/Images/homeBg.webp";
import styles from "../Homepage/HomePage.module.css";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import image from "../../assets/Images/Rectangle.png";
// import womanImg from "../../assets/Images/womanImg.png";
import womanImg2 from "../../assets/Images/womanImg2.webp";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Categories, Works } from "../../mockData";
import {
  clearForm,
  resetNextStep,
  setPreviousStep,
} from "../../store/slices/UserSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  categoryList,
  setActiveCategory,
  setActiveTab,
} from "../../store/slices/createJob";
import { STATUS_MSG } from "../../utils/StatusMsg";
import { STATUS_CODES } from "../../utils/StatusCode";
import Toster from "../../utils/Toaster";
import { Autocomplete } from "@react-google-maps/api";
import SublyApi from "../../helpers/Api";
import Loader from "../../utils/Loader/Loader";
import DOMPurify from "dompurify";
import { SETTING_OPTION, SETTING_OPTION_TYPE } from "../../utils/Constants";
import { Icon } from "@iconify/react/dist/iconify.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/Styles/Common.css";
import { Rating } from "@mui/material";
// import backArrow from "../../assets/Images/backArrow.png";
// import nextArrow from "../../assets/Images/nextArrow.png";
// import { FcNext, FcPrevious } from "react-icons/fc";

//---------function for homepage----------
function HomePage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [aboutUsData, setAboutUsData] = useState();
  const autocomplete = useRef(null);
  const autocomplete2 = useRef(null);
  // const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [sourceGeometry, setSourceGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });
  const [destinationGeometry, setDestinationGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;

  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${placeApiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setGoogleMapsLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const AbousUsList = async () => {
    setIsLoading(true);
    await SublyApi.getCustomerSettingList(
      null,
      SETTING_OPTION_TYPE.PAGE,
      SETTING_OPTION.ABOUT_US
    ).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        const dataText = response.data[0]?.option_value || "";
        setAboutUsData(dataText);
        setIsLoading(false);
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    AbousUsList();
  }, []);

  const CustomOption = ({ innerProps, label, data, isFocused, isSelected }) => (
    <div
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        margin: "15px",
        cursor: "pointer",
        backgroundColor: isSelected ? "#dddddd" : "transparent",
        borderRadius: "4px",
        padding: "6px",
      }}
      className="customBox"
    >
      <span
        style={{
          margin: "0px 5px 0px 0px",
          fontSize: "30px",
          color: "#A1A1A1",
        }}
      ></span>
      {/* Render the icon */}
      <span
        style={{
          margin: "0px 4px",
          color: "#858585",
          fontSize: "20px",
          fontWeight: "400",
        }}
      >
        {label}
      </span>
    </div>
  );

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedB2bOption, setSelectedB2bOption] = useState(null);
  const [categoriesList, setCategoriesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ratingList, setRatingList] = useState([]);
  const [isB2bSelect, setIsB2bSelect] = useState(false);

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.label.toLowerCase() == "business to business") {
        setIsB2bSelect(true);
      } else {
        setIsB2bSelect(false);
      }
    }
  }, [selectedOption]);

  const customOption = categoriesList.map((val) => {
    return {
      value: val.id,
      label: val.name,
      icon: val.icon,
    };
  });

  const customB2bOption = [
    { value: "job-creation", label: "Job Creation", icon: "" },
    { value: "enquiry", label: "Enquiry", icon: "" },
  ];

  const fetchRatingList = () => {
    setIsLoading(true);
    let res = SublyApi.fetchRatingListData();
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setRatingList(response.data);
      } else {
        if (
          (response && response.code == STATUS_CODES.SERVER_VALIDATION) ||
          (response &&
            response.data &&
            response.data.code == STATUS_CODES.SERVER_VALIDATION)
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchRatingList();
  }, []);

  useEffect(() => {
    dispatch(categoryList()).then((responseJson) => {
      const response = responseJson.payload;
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setCategoriesList(response.data);
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
  }, []);

  useEffect(() => {
    dispatch(clearForm(true));
    dispatch(resetNextStep());
    dispatch(setPreviousStep(false));
  }, [dispatch]);

  const handleCalculate = () => {
    // setShowErrorMsg(true);

    if (!selectedOption?.value) {
      Toster(t("Please select a category."), "error");
      return;
    }

    if (
      selectedOption.label.toLowerCase() !== "business to business" &&
      (!sourceGeometry.lat || !destinationGeometry.lat)
    ) {
      Toster(t("Please select a location."), "error");
      return;
    }

    if (
      selectedOption.label.toLowerCase() == "business to business" &&
      !selectedB2bOption?.value
    ) {
      Toster(t("PLEASE_CHOOSE_OPTION"), "error");
      return;
    }

    dispatch(setActiveCategory(selectedOption.value));
    dispatch(setActiveTab("first"));
    navigate("/create-job", {
      state: {
        fromHomePage: true,
        sourceGeometry,
        destinationGeometry,
        selectedB2bOption: selectedB2bOption?.value,
      },
    });
  };

  const handleLocationChange = (value, type) => {
    if (type == "sourceLocation") {
      setSourceGeometry((prev) => ({
        ...prev,
        lat: "",
        lng: "",
        address: value,
      }));
    } else if (type == "destinationLocation") {
      setDestinationGeometry((prev) => ({
        ...prev,
        lat: "",
        lng: "",
        address: value,
      }));
    }
  };

  const handlePlaceSelected = (place, type) => {
    const getAddressComponent = (components, type) => {
      return (
        components.find((component) => component.types.includes(type))
          ?.long_name || ""
      );
    };

    if (type === "sourceLocation") {
      const placeDetails = autocomplete.current.getPlace();
      const addressComponents = placeDetails.address_components;
      const city = getAddressComponent(addressComponents, "locality");
      const postalCode = getAddressComponent(addressComponents, "postal_code");
      setSourceGeometry({
        address: placeDetails.formatted_address,
        lat: placeDetails.geometry.location.lat(),
        lng: placeDetails.geometry.location.lng(),
        city: city !== undefined ? city : "",
        postalCode: postalCode !== undefined ? postalCode : "",
        // city: city,
        // postalCode: postalCode,
      });
    } else if (type === "destinationLocation") {
      const placeDetails = autocomplete2.current.getPlace();
      const addressComponents = placeDetails.address_components;
      const city = getAddressComponent(addressComponents, "locality");
      const postalCode = getAddressComponent(addressComponents, "postal_code");

      setDestinationGeometry({
        address: placeDetails.formatted_address,
        lat: placeDetails.geometry.location.lat(),
        lng: placeDetails.geometry.location.lng(),
        city: city !== undefined ? city : "",
        postalCode: postalCode !== undefined ? postalCode : "",
        // city: city,
        // postalCode: postalCode,
      });
    }
  };

  // --------function for submit form--------
  const onSubmit = async (formdata) => {};

  // Function to handle the next slide
  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.slickNext(); // Move to the next slide
    }
  };

  // Function to handle the previous slide
  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.slickPrev(); // Move to the previous slide
    }
  };

  const settings = {
    lazyLoad: "ondemand",
    dots: false,
    infinite: true,
    // arrows: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="main">
        <div className="spaceTopManage">
          <div
            style={{
              backgroundImage: `url(${homeBg})`,
              backgroundSize: "cover",
              width: "100%",
              padding: "40px 0px",
              backgroundPositionX: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Container>
              <div className={styles.topHeadTitle}>
                <div className={styles.topSubHeadTitle}>
                  {t("WELCOME_TO_CITY2CITY")}
                </div>
                <div className={styles.topSuggestText}>
                  {t("HOME_TAB_TEXT")}
                </div>
              </div>

              <div className={styles.formBackground}>
                <Container>
                  <div className={styles.formBox}>
                    <div className={styles.moveTextInner}>{t("BOOK_MOVE")}</div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group className="formSelect addressFieldHome">
                        <Select
                          defaultValue={selectedOption}
                          onChange={setSelectedOption}
                          options={customOption}
                          components={{ Option: CustomOption }}
                          placeholder={
                            <span className="customSelectPlaceholder">
                              {t("WHAT_MOVING")}
                            </span>
                          }
                          aria-label={t("WHAT_MOVING")}
                          isSearchable={false}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              borderRadius: 0,
                              primary25: "#f2f2f2",
                              primary: "#000000;",
                              primary50: "#f2f2f2",
                            },
                          })}
                        />
                      </Form.Group>
                      {isB2bSelect && (
                        <Form.Group className="mb-3 formSelect">
                          <Select
                            defaultValue={selectedB2bOption}
                            onChange={setSelectedB2bOption}
                            options={customB2bOption}
                            components={{ Option: CustomOption }}
                            placeholder={t("Choose Option")}
                            styles={{
                              placeholder: () => ({
                                fontSize: "20px",
                                fontWeight: "500",
                                color: "#6E6E6E",
                                position: "absolute",
                                top: "2px",
                                left: "15px",
                              }),
                            }}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                borderRadius: 0,
                                primary25: "#f2f2f2",
                                primary: "#000000;",
                                primary50: "#f2f2f2",
                              },
                            })}
                          />
                        </Form.Group>
                      )}
                      {!isB2bSelect && (
                        <>
                          <InputGroup className="addressFieldHome">
                            <InputGroup.Text className={styles.inputGroup}>
                              <Icon
                                icon="line-md:my-location"
                                width="28"
                                height="28"
                                color="white"
                              />
                            </InputGroup.Text>
                            {googleMapsLoaded && (
                              <Autocomplete
                                placeApiKey={placeApiKey}
                                onLoad={(auto) => {
                                  autocomplete.current = auto;
                                }}
                                onPlaceChanged={(place) =>
                                  handlePlaceSelected(place, "sourceLocation")
                                }
                                className={styles.inputAutoComplete}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder={t("COLLECTION_ADDRESS")}
                                  value={sourceGeometry.address}
                                  onChange={(e) =>
                                    handleLocationChange(
                                      e.target.value,
                                      "sourceLocation"
                                    )
                                  }
                                  className={styles.formControl}
                                />
                              </Autocomplete>
                            )}
                          </InputGroup>

                          <InputGroup size="sm" className="addressFieldHome">
                            <InputGroup.Text className={styles.inputGroup}>
                              <Icon
                                icon="mingcute:location-2-line"
                                width="26"
                                height="26"
                                color="white"
                              />
                            </InputGroup.Text>
                            {googleMapsLoaded && (
                              <Autocomplete
                                placeApiKey={placeApiKey}
                                onLoad={(auto) => {
                                  autocomplete2.current = auto;
                                }}
                                onPlaceChanged={(place) =>
                                  handlePlaceSelected(
                                    place,
                                    "destinationLocation"
                                  )
                                }
                                className={styles.inputAutoComplete}
                              >
                                <Form.Control
                                  type="text"
                                  placeholder={t("DELIVERY_ADDRESS")}
                                  value={destinationGeometry.address}
                                  onChange={(e) =>
                                    handleLocationChange(
                                      e.target.value,
                                      "destinationLocation"
                                    )
                                  }
                                  className={styles.formControl}
                                />
                              </Autocomplete>
                            )}
                          </InputGroup>
                        </>
                      )}

                      <div className={styles.buttonControls}>
                        <button
                          type="button"
                          className={styles.rightButton}
                          onClick={() => handleCalculate()}
                        >
                          {t("FREE_INSTANT_QUOTE")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </Container>
              </div>
            </Container>
          </div>

          {/* <Container>
            <div className={styles.ratingPointsSection}>
              <div className="ratingPointsSection">
                {ratingList?.length > 1 && (
                  <Slider {...settings}>
                    {ratingList.map((items, ind, arr) => {
                      return (
                        <div className={styles.ratingMidSection}>
                          <div className={styles.ratingTitle}>
                            {items.review_title}
                          </div>
                          <div className={styles.ratingDescription}>
                            {items.review_desc}
                          </div>
                          <div>
                            <Rating
                              name="reviewRating"
                              value={Math.round(items?.rating)}
                              precision={0.5}
                              readOnly
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>
          </Container> */}

          <div className={styles.ratingPointsSections}>
            <div className="ratingPointsSection">
              {ratingList?.length > 1 && (
                <Slider {...settings} ref={carouselRef}>
                  {ratingList.map((items, ind, arr) => {
                    return (
                      <div className={styles.ratingSection} key={ind}>
                        <div className={styles.ratingMidSection}>
                          <div>
                            <Rating
                              style={{
                                color: "#FFFFFF",
                                fontSize: "32px",
                                gap: "6px",
                              }}
                              name="reviewRating"
                              value={Math.round(items?.rating)}
                              precision={0.5}
                              readOnly
                            />
                          </div>
                          <div className={styles.ratingTitle}>
                            {items.review_title}
                          </div>
                          <div className={styles.ratingDescription}>
                            {items.review_desc}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
          </div>

          <div className={styles.sliderBtns}>
            <Icon
              icon="famicons:arrow-back-circle-sharp"
              // width="42"
              // height="42"
              color="#333333"
              onClick={handlePrev}
            />

            <Icon
              icon="famicons:arrow-back-circle-sharp"
              // width="42"
              // height="42"
              color="#333333"
              onClick={handleNext}
              hFlip={true}
            />
          </div>

          <div className={styles.aboutPage}>
            <div className={styles.aboutWorks}></div>
            <Container>
              <h5>
                {t("HOW")} {t("WORKS")}
              </h5>
              <Row>
                {Works.map((item1, index) => (
                  <Col
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    key={index}
                    className="mb-3"
                  >
                    <div className={styles.worksSection}>
                      {index % 2 !== 0 ? ( // Check if the index is odd
                        <div>
                          <div>
                            <img
                              src={item1.image}
                              alt="works-image"
                              loading="lazy"
                            />
                          </div>
                          <div className={styles.worksSectionText}>
                            {item1.text}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className={styles.worksSectionText}>
                            {item1.text}
                          </div>
                          <div>
                            <img
                              src={item1.image}
                              alt="works-image"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

          <div className={styles.aboutPage}>
            <div className={styles.aboutWorks}></div>
            <Container>
              {categoriesList.length >= 1 && (
                <Row>
                  <Col lg={7} className="mb-3">
                    <div className={styles.loveDoingText}>
                      {t("CATEGORIES")} {t("DOING")}
                    </div>
                    <div
                      className={styles.categoryImgSection}
                      onClick={() => {
                        dispatch(setActiveCategory(categoriesList[0].id));
                        navigate("/create-job");
                      }}
                    >
                      <img
                        src={Categories[0].image}
                        alt="categories-image"
                        className={styles.categoryImg1}
                        loading="lazy"
                      />
                      <p className={styles.aboveText}>
                        {categoriesList[0].name}
                      </p>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div
                      className={`${styles.categoryImgSection} mb-3`}
                      onClick={() => {
                        dispatch(setActiveCategory(categoriesList[1].id));
                        navigate("/create-job");
                      }}
                    >
                      <img
                        src={Categories[1].image}
                        alt="categories-image"
                        className={styles.categoryImg2}
                        loading="lazy"
                      />
                      <p className={styles.aboveText}>
                        {categoriesList[1].name}
                      </p>
                    </div>
                    <div
                      className={styles.categoryImgSection}
                      onClick={() => {
                        dispatch(setActiveCategory(categoriesList[2].id));
                        navigate("/create-job");
                      }}
                    >
                      <img
                        src={Categories[2].image}
                        alt="categories-image"
                        className={styles.categoryImg3}
                        loading="lazy"
                      />
                      <p className={styles.aboveText}>
                        {categoriesList[2].name}
                      </p>
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </div>

          <div className={styles.aboutPage}>
            <div className={styles.aboutBackground}>
              <Container>
                <div className={styles.aboutBox}>
                  <div className={styles.aboutImage}>
                    <img src={womanImg2} alt="image" />
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(aboutUsData),
                  }}
                  className={styles.aboutText}
                ></div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HomePage;
